add-show-modal {
    display: block;
    height: 95%;
    width: 95%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    .modal,
    .modal-dialog {
        height: 100%;
    }
    .modal-content {
        max-height: 80%;
    }
    .modal-body {
        max-height: 70%;
        overflow: auto;
    }
    .poster {
        height: 195px;
        width: auto;
        img {
            width: 92px;
        }
    }
}