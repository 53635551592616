app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;
  overflow: hidden;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -110%, 0);
  opacity: 1;
}

.content {
  position: relative;
  height: calc(100% - 40px);
  overflow: auto;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;

  #log-in-button {
    display: inline-block;
    margin-top: 15px;
  }
}

.navbar {
  position: relative;
  width: 100%;
  background-color: #081c24;
  color: white;
  text-align: left;
  padding-top: 15px;
  padding: 10px;
  box-sizing: border-box;
}

[white] {
  color: white;
}

.dropdown-item {
  cursor: pointer;
}

.sign-in {
  position: absolute;
  right: 5px;
  top: 2px;
}

.profile-menu-container {
  position: absolute;
  right: 5px !important;
  top: 30px !important;
  width: 200px;
  display: inline-block;
  z-index: 100000;
}

.poster {
  border: 1px solid darkgrey;
  background-color: lightgrey;
  padding: 3px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-right: 4px;
  margin-bottom: 4px;
  text-align: center;
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  min-height: 168px;
  &.archived {
    background-color: rgb(119, 0, 0);
    color: white;
  }
}

.poster img {
  width: 100%;
  display: inline-block;
}

.poster button {
  z-index: 1;
}

.poster .title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.poster .date {
  display: block;
  font-size: .95em;
}

.edit {
  width: 40px;
  height: 40px;
}

.poster .edit {
  background-color: rgb(0, 52, 163);
  color: white;
  font-weight: bold;
  position: absolute;
  right: 0px;
  top: 0px;
}

.poster .button {
  padding: 5px 10px;
}

.button-row {
  text-align: center;
  display: block;
}

button,
.poster {
  cursor: pointer;
}

.btn-add-show {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  border-radius: 100%;
  box-shadow: 3px 3px 10px #888;
  padding: 15px !important;
  width: 60px;
  height: 60px;
  margin: 0px;
}
