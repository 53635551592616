* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: auto;
}

[pointer],
.pointer {
    cursor: pointer;
}

[inline-block] {
    display: inline-block;
}

[float-left] {
    float: left !important;
}

[float-right] {
    float: right !important;
}

[clearfix] {
    clear: both;
}

[text-center] {
    text-align: center;
}

.relative {
    position: relative;
}

.modal-backdrop {
    opacity: .7;
}

.modal-content {
    box-shadow: 5px 5px 15px 5px rgb(22, 22, 22);
    min-width: 340px;
    min-height: 400px;
}

.modal-dialog {
    z-index: 1050;
}

.modal.show {
    display: block;
}

.flex {
    display: flex;
}