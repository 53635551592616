show-info-modal {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;

    pre {
        margin: 0;
        padding: 0;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            margin: 0;
            text-align: left;
        }
    }

    .history-edit-date {
        min-height: 40px;

        .close {
            margin-top: 5px;
        }
    }

    .modal {
        &.show {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .history-list {
        max-height: 180px;
        overflow: auto;
    }

    .search-link{
        display:inline-block;
        width: 40px;
        padding-left: 10px;
        padding-top:15px;
    }

    .refresh-poster-btn {
        display: inline-block;
        max-height: 40px;
        margin-left:10px;
        margin-top: 5px;
    }
}